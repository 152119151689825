export type PodcastProps = {
  url: string;
  title: string;
  imageSrc: string;
  genre: string;
};

export default function Podcast({ url, title, imageSrc, genre }: PodcastProps) {
  return (
    <a href={url} class="media-block">
      <div class="media-box ratio-container ratio1x1">
        <img
          class="media-box__image lazyload"
          alt={title}
          data-src={imageSrc}
        />
      </div>
      <div class="media-block__meta">
        <h2 class="media-block__title">{title}</h2>
        <div class="media-block__sub">{genre}</div>
      </div>
      <div class="media-block__go">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
            fill="#aaaaaa"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </div>
    </a>
  );
}
